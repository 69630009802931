import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ShortcutPage from './ShortcutPage';
import { LoginDialog, LogoutConfirmDialog } from './AuthDialogs';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Inter', sans-serif",
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#fff',
    boxSizing: 'border-box', // Ensure padding is included in the width
  },
  logo: {
    width: '32px',
    height: '32px',
    marginRight: '10px',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  loginButton: {
    backgroundColor: '#4d79ff',
    color: '#fff',
    border: 'none',
    borderRadius: '10px',
    padding: '5px 15px',
    cursor: 'pointer',
    fontSize: '1rem',
    marginRight: '20px', // Add margin to the right
    '&:hover': {
      backgroundColor: '#3a5bbf',
    },
  },
  heroContainer: {
    height: "100vh",
    backgroundImage: 'url(e3d_ai.gif)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingTop: "0px",
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    color: "#ffffff",
  },
  hero: {
    height: "60vh",
    borderRadius: "20px", // Makes the box rounded
    padding: "10px", // Adds inner spacing
    color: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    maxWidth: "800px",
    marginTop: "20px", // Adds outer spacing
    margin: "20px", // Adds outer spacing
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", // Adds a subtle shadow
    backgroundColor: "#333333", // Adds a solid background color
  },
  heroTitle: {
    fontSize: "3.5rem",
    fontWeight: "700", // Bold weight
    marginTop: "0px",
    marginBottom: "10px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "2.00rem", // Half the size on mobile
    },
  },
  heroText: {
    fontSize: "1.5rem",
    marginBottom: "5px",
    maxWidth: "800px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.00rem", // Half the size on mobile
    },
  },
  gifImage: {
    width: "450px",
    height: "auto",
    objectFit: "cover", // Ensures the image scales nicely within the defined dimensions
    marginBottom: "5px",
  
    // Media query for portrait and small devices
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px', // Smaller dimensions for small devices

      '@media (orientation: landscape)': {
        width: '600px',
        height: '100px', // Adjust dimensions for landscape orientation
      },
    
      '@media (orientation: portrait)': {
        width: '300px',
        height: '300px', // Adjust dimensions for portrait orientation
      },
    },
  
  },  
  gifImageUsers: {
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensures the image scales nicely within the defined dimensions
    marginBottom: "5px",
    opacity: 0.5, // Set transparency to 100%
  
    // Media query for portrait and small devices
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%', // Smaller dimensions for small devices
  
      '@media (orientation: landscape)': {
        width: '100%',
        height: '100%', // Adjust dimensions for landscape orientation
      },
    
      '@media (orientation: portrait)': {
        width: '100%',
        height: '100%', // Adjust dimensions for portrait orientation
      },
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    "& button": {
      padding: "15px 30px",
      borderRadius: "25px",
      border: "none",
      fontSize: "1rem",
      cursor: "pointer",
      textTransform: "uppercase",
    },
  },
  btnPrimary: {
    backgroundColor: "#4d79ff",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#6c00ff",
    },
  },
  btnOutline: {
    backgroundColor: "transparent",
    border: "2px solid #ffffff",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#4d79ff",
    },
  },
  aboutSection: {
    width: '90%',
    height: '100vh', // Ensure the section takes up the full viewport height
    backgroundImage: 'url(e3d_users.gif)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '10px',
    color: '#fff', // Ensure text is readable on the background
    flexDirection: 'column',
    justifyContent: 'space-between', // Space out the content and push featuresContainer to the bottom
  },
  aboutTitle: {
    fontSize: "2.5rem",
    marginBottom: "20px",
    color: "#4d79ff",
    textAlign: 'center',
  },
  aboutText: {
    fontSize: "1.2rem",
    maxWidth: "800px",
    margin: "0 auto 40px",
    textAlign: 'left',
  },
  featuresSection: {
    height: "100vh",
    padding: "50px 20px",
    backgroundImage: 'url(e3d_nft_view.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    textAlign: "center",
    color: "#333333", // Dark gray text for good contrast
    justifyContent: "center", // Center the columns horizontally
    alignItems: "center", // Center the columns vertically

    // Media query for portrait and small devices
    [theme.breakpoints.down('sm')]: {
      // Smaller dimensions for small devices
      '@media (orientation: portrait)': {
        height: "auto", // Adjust height to fit content
      },
    },
  },
  featuresContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)", // Two columns per row
    gap: "20px",

   // Media query for portrait and small devices
   [theme.breakpoints.down('sm')]: {
    // Smaller dimensions for small devices
      '@media (orientation: portrait)': {
        gridTemplateColumns: "repeat(1, 1fr)", // Two columns per row
      },
    },
  },
  featuresTitle: {
    marginTop: "70px",
    fontSize: "2.5rem",
    marginBottom: "10px",
    color: "#FFFFFF",
  },
  featuresText: {
    fontSize: "1.2rem",
    maxWidth: "800px",
    margin: "0 auto 40px",
    color: "#FFFFFF",
    textAlign: 'left',
  },
  featureCard: {
    width: "360px",
    backgroundColor: "#16213e",
    paddingTop: "0px",
    paddingLeft: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    textAlign: "left",
    color: "#ffffff",
  },
  featureIcon: {
    display: "flex",
    alignItems: "center",
    paddingRight: "20px",
  },
  featureTitle: {
    fontSize: "1.5rem",
    marginBottom: "10px",
    paddingLeft: "20px",
    color: "#6c00ff",
  },
  newsletterSection: {
    backgroundColor: "#0f3460",
    padding: "10px",
    textAlign: "center",
    color: "#ffffff",
  },
  newsletterTitle: {
    fontSize: "2.5rem",
    marginBottom: "20px",
    color: "#4d79ff",
  },
  newsletterText: {
    fontSize: "1.2rem",
    maxWidth: "800px",
    margin: "0 auto 30px",
  },
  newsletterImage: {
    width: "400px",
    height: "auto",
    paddingBottom: "20px",
  },
  newsletterButton: {
    backgroundColor: "#4d79ff",
    padding: "15px 30px",
    borderRadius: "25px",
    color: "#ffffff",
    fontSize: "1rem",
    textTransform: "uppercase",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#6c00ff",
    },
  },
  podcastSection: {
    background: 'linear-gradient(135deg, #e8f5e9, #dcedc8)', // Light green gradient
    padding: '10px',
    textAlign: 'center',
    color: '#333333',
  },
  podcastTitle: {
    fontSize: '2.5rem',
    fontWeight: '700',
    marginBottom: '20px',
    color: '#6c63ff', // Accent color
  },
  podcastImage: {
    width: "400px",
    height: "auto",
    paddingBottom: "20px",
  },
  podcastDescription: {
    fontSize: '1.2rem',
    maxWidth: '800px',
    margin: '0 auto 30px',
    lineHeight: '1.6',
  },
  audioPlayerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px', // Adds space between the audio player and the button
    marginTop: '20px',
  },
  audioPlayer: {
    outline: 'none',
    width: '300px', // Fixed width for consistent alignment
    height: '40px', // Ensure consistent height with the button
    border: 'none',
    borderRadius: '10px',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
  },
  playButton: {
    padding: '15px 30px',
    fontSize: '1rem',
    borderRadius: '25px',
    border: 'none',
    cursor: 'pointer',
    background: 'linear-gradient(135deg, #6c63ff, #4d79ff)', // Gradient for button
    color: '#ffffff',
    transition: 'all 0.3s ease',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: 'linear-gradient(135deg, #4d79ff, #6c63ff)', // Reverse gradient on hover
      boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.15)',
      transform: 'translateY(-2px)', // Slight lift on hover
    },
  },
  footer: {
    backgroundColor: "#0f3460",
    color: "#cccccc",
    textAlign: "center",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerText: {
    fontSize: "0.9rem",
  },
  discordIcon: {
    fontSize: "1.5rem",
    color: "#ffffff",
    textDecoration: "none",
    "&:hover": {
      color: "#7289da",
    },
  },
  tokenSection: {
    backgroundColor: "#16213e",
    padding: "10px",
    textAlign: "center",
    color: "#ffffff",
  },
  tokenTitle: {
    fontSize: "2.5rem",
    marginBottom: "20px",
    color: "#4d79ff",
  },
  tokenText: {
    fontSize: "1.0rem",
    maxWidth: "800px",
    margin: "0 auto 30px",
    textAlign: 'left',
  },
  tokenLink: {
    fontSize: "1.2rem",
    color: "#4d79ff",
    textDecoration: "none",
    '&:hover': {
      textDecoration: "underline",
    },
  },
  tokenImage: {
    width: "400px",
    height: "auto",
    margin: "20px auto",
    display: "block",
  },
  section: {
    padding: "50px 20px",
    textAlign: "center",
  },
  sectionTitle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#4d79ff",
    marginBottom: "20px",
  },
  sectionText: {
    fontSize: "1.2rem",
    maxWidth: "800px",
    margin: "0 auto 30px",
    color: "#FFFFFF",
    lineHeight: "1.6",
    textAlign: 'left', 
  },
  sectionTextCenter: {
    fontSize: "1.2rem",
    marginTop: "20px",
    maxWidth: "800px",
    margin: "0 auto 30px",
    color: "#FFFFFF",
    lineHeight: "1.6",
    textAlign: 'center', 
  },
  button: {
    backgroundColor: "#4d79ff",
    color: "#ffffff",
    padding: "15px 30px",
    fontSize: "1rem",
    borderRadius: "25px",
    border: "none",
    cursor: "pointer",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "#6c00ff",
    },
  },
  networkGraphSection: {
    height: "100vh",
    backgroundImage: 'url(e3d_pcap_short.gif)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: "50px 20px",
    textAlign: "center",
  },
  networkGraphSectionTitle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: "#4d79ff",
    marginTop: "170px",
    marginBottom: "20px",
  },
  networkGraphContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    background: "#000000",
    borderRadius: "15px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  },
  networkGraphImage: {
    width: "80%",
    maxWidth: "600px",
    height: "auto",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  },
  codeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2),
  },
  codeBlock: {
    width: '700px',
    padding: theme.spacing(2),
    position: 'relative',
    backgroundColor: '#A9A9A9',
    border: '1px solid #ddd',
    borderRadius: theme.shape.borderRadius,
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    textAlign: 'left',
  },
  copyButton: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  nonMobile: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}));


const HeroSection = ({setShowHomePage, setShowShortcutPage}) => {
  const classes = useStyles();

  return (
    <div className={classes.heroContainer}>
      <section className={classes.hero}>
        <h1 className={classes.heroTitle}>Revolutionize Blockchain Exploration</h1>
        <img src="./e3d_token_graph.png" alt="3D visualisation" className={classes.gifImage} />
        <p className={classes.heroText}>
          Experience blockchain like never before. Dive into real-time 3D
          visualizations of Ethereum transactions, tokens, and NFTs. Empower
          your decision-making with the most advanced tools available.
        </p>
      </section>
      <div className={classes.buttonContainer}>
        <ShortcutPage
          setShowHomePage={setShowHomePage}
          setShowShortcutPage={setShowShortcutPage}
        />
      </div>
    </div> 
  );
};


const FeaturesSection = () => {
  const classes = useStyles();

  const features = [
    {
      title: "Real-Time 3D Visualization",
      description:
        "Explore Ethereum blockchain activities in real-time through a dynamic 3D sphere that displays token and NFT transactions.",
      icon: "🌐",
    },
    {
      title: "NFT Minting and Revenue Sharing",
      description:
        "Future: Mint NFTs from saved graphs with built-in revenue sharing for the original NFT owners, fostering a collaborative ecosystem.",
      icon: "🖼️",
    },
    {
      title: "Seamless Integration of Tokens and NFTs",
      description:
        "Interact with both token and NFT transactions seamlessly in one unified platform, reflecting real-world trends.",
      icon: "🔗",
    }, 
    {
      title: "Advanced Layouts and Customization",
      description:
        "Personalize your blockchain exploration with layouts like Sphere, Grid, Pyramid, and Tube for unique perspectives.",
      icon: "📐",
    },
    {
      title: "Auto Refresh and Mobile Optimization",
      description:
        "Stay updated with auto-refreshing graphs and enjoy E3D’s modern functionality across mobile devices.",
      icon: "📱",
    },
    {
      title: "Blockchain Transaction Viewer",
      description:
        "Explore every blockchain transaction in detail, providing transparency and a comprehensive view of Ethereum activity.",
      icon: "🔍",
    },
    {
      title: "Token Volume Indicators",
      description:
        "Visualize transaction activity through token volume indicators, highlighting market trends and surges with ease.",
      icon: "📊",
    },
    {
      title: "Extensive Token Price and Market View",
      description:
        "Track real-time token prices, market caps, and historical trends to make informed, data-driven decisions.",
      icon: "💹",
    },
  ];

  return (
    <section className={classes.featuresSection}>
      <h2 className={classes.featuresTitle}>E3D Features</h2>
      <p className={classes.featuresText}>
        E3D revolutionizes blockchain visualization with an unparalleled suite of innovative features, transforming 
        complex data into an intuitive, interactive 3D experience. By enabling users to explore tokens, NFTs, and 
        their relationships in real-time, E3D empowers a deeper understanding of blockchain activity through stunning 
        visualizations and features. 
      </p>
      <div className={classes.featuresContainer}>
        {features.map((feature, index) => (
          <div key={index} className={classes.featureCard}>
            <div className={classes.featureIcon}>
              {feature.icon} <h2 className={classes.featureTitle}>{feature.title}</h2>
            </div>
            <p className={classes.featureDescription}>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};




const AboutSection = () => {
  const classes = useStyles();

  const features = [
    {
      title: "RUnmatched Visualization",
      description:
        "Navigate the blockchain with ease using real-time, 3D graphs that bring your data to life.",
      icon: "🌐",
    },
    {
      title: "Token Swapping",
      description:
        "Perform secure transactions without leaving the visualization environment",
      icon: "🖼️",
    },
    {
      title: "Seamless Integration of Tokens and NFTs",
      description:
        "Turn your 3D blockchain snapshots into NFTs and monetize your creativity in our marketplace.",
      icon: "🔗",
    }, 
  ];

  return (
    <section className={classes.aboutSection}>
      <div>
        <h2 className={classes.aboutTitle}>Why Choose E3D?</h2>
        <p className={classes.aboutText}>
          E3D is a cutting-edge blockchain visualization platform that transforms Ethereum blockchain data into 
          interactive 3D visualizations. 
        </p>
        <p className={classes.aboutText}>
          By providing a unique and immersive experience, E3D empowers users to 
          explore, analyze, and understand blockchain data in a new light. With real-time insights, advanced 
          features, and a user-friendly interface, E3D is the ultimate tool for blockchain enthusiasts, investors, 
          and developers. Discover the future of blockchain exploration with E3D.
        </p>
      </div>
      <div className={classes.featuresContainer}>
        {features.map((feature, index) => (
          <div key={index} className={classes.featureCard}>
            <div className={classes.featureIcon}>
              {feature.icon} <h2 className={classes.featureTitle}>{feature.title}</h2>
            </div>
            <p className={classes.featureDescription}>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}


const PodcastSection = () => {
  const classes = useStyles();

  const playPodcast = () => {
    // Access the global audio player
    const globalAudioPlayer = document.getElementById('global-audio-player');
  
    // Set the podcast source and play
    globalAudioPlayer.src = '/E3D_Podcast.wav'; // Replace with the actual path
    globalAudioPlayer.play().catch((error) => {
      console.error('Failed to play podcast:', error);
    });
  
    // Navigate to another page
    window.location.href = './token'; // Adjust path as needed
  };
  

  return (
    <section className={classes.podcastSection}>
      <h2 className={classes.podcastTitle}>E3D AI Podcast</h2>
      <p className={classes.podcastDescription}>
        Join our two expert commentators as they dive deep into the world of E3D, 
        explaining how this groundbreaking platform revolutionizes blockchain 
        visualization. With engaging discussions, insightful analyses, and 
        stories of innovation, the E3D AI Podcast offers an exciting way to 
        discover the uniqueness of E3D. Learn how E3D turns blockchain data 
        into art and empowers users to explore and interact with the Ethereum 
        blockchain like never before.
      </p>      
      <div>
          <img src="e3d-podcast.png" alt="Podcast" className={classes.podcastImage} />
        </div>
      <div className={classes.audioPlayerContainer}>
        <audio
          className={classes.audioPlayer}
          controls
          src="/E3D_Podcast.wav" // Replace with the actual path to your podcast file
        >
          Your browser does not support the audio element.
        </audio>
        
      {/* 
        <button className={classes.playButton} onClick={playPodcast}>
          Play Podcast while using E3D
        </button>
      */}


      </div>
    </section>
  );
};


const TokenSection = () => {
  const classes = useStyles();
  return (
    <section className={classes.tokenSection}>
      <h2 className={classes.tokenTitle}>E3D Token</h2>
      <p className={classes.tokenText}>
        The E3D token is the native token of the E3D platform, enabling transactions, premium features, and community rewards. 
        E3D tokens are ERC-20 compliant and are used to power the E3D ecosystem, providing users with a seamless experience
        E3D tokens are available for purchase on the E3D app, Uniswap, and Sushiswap. 
        There are separate liquidity pools for E3D tokens on Uniswap and Sushiswap.
      </p>
      <p className={classes.tokenText}>
        <strong>Contract Address:</strong> 0x6488861b401f427d13b6619c77c297366bcf6386
      </p>
      <img src="e3d_token.png" alt="E3D Token" className={classes.tokenImage} /> 
      <a 
        href="https://etherscan.io/token/0x6488861b401f427d13b6619c77c297366bcf6386" 
        target="_blank" 
        rel="noopener noreferrer" 
        className={classes.tokenLink}
      >
        View on Etherscan
      </a>
    </section>
  );
};


const CodeBlockWithCopyButton = () => {
  const classes = useStyles();

  const copyCode = () => {
    const codeBlock = document.getElementById('code-block').innerText;
    navigator.clipboard.writeText(codeBlock).then(() => {
      //alert('Code copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy code: ', err);
    });
  };

  return (
    <div className={classes.codeContainer}>
      <div className={classes.codeBlock} id="code-block">
        <pre>
          {`tshark -r test.pcap -T fields -e frame.number -e ip.src -e ip.dst -e frame.len -E separator=, -E occurrence=f -Y "ip" | gawk -F, '
BEGIN { OFS = "," }
{
  client_bytes[$2 OFS $3] += $4;
  server_bytes[$3 OFS $2] += $4;
}
END {
  print "Group,Client Addr,Server Addr,Client Bytes,Server Bytes,Events";
  for (i in client_bytes) { keys[++count] = i; }
  asort(keys);
  for (j = 1; j <= count; j++) {
    split(keys[j], s, OFS);
    client = client_bytes[keys[j]];
    server = server_bytes[keys[j]] ? server_bytes[keys[j]] : 0;
    print "Group1", s[1], s[2], client, server, 0;
  }
}' > test.csv`}
        </pre>
      </div>
      <Button className={classes.copyButton} onClick={copyCode}>
        Copy Command
      </Button>
    </div>
  );
};


const NetworkGraphSection = () => {
  const classes = useStyles();

  return (
    <section className={classes.networkGraphSection}>
      <h2 className={classes.networkGraphSectionTitle}>📡 Network Graph Visualization</h2>
      <p className={classes.sectionText}>
        E3D supports PCAP file visualization on non-mobile devices, allowing you to explore network traffic in a fully interactive 3D space.
        Upload your .pcap or .csv file, and watch the connections come to life in real-time.
      </p>
      <div className={classes.nonMobile}>
        <p className={classes.sectionText}>
            Behind the scenes, we use tshark and awk to process .pcap files and extract the IPv4 addresses and byte counts. 
            We do not store anything on the server, but if you would prefer to generate the .csv file locally, use this command:
        </p>

        <CodeBlockWithCopyButton />

        <p className={classes.sectionTextCenter}>
          Try it now and let us know what features you'd like to see next!
        </p>
        <a href="/pcap" style={{ textDecoration: "none" }}>
          <button className={classes.button}>Try It Now</button>
        </a>
      </div>
    </section>
  );
};

const NewsletterSection = ({subscribeNewsletter}) => {
  const classes = useStyles();

  return (
    <section className={classes.newsletterSection}>
      <h2 className={classes.newsletterTitle}>Stay Ahead with the E3D AI Newsletter</h2>
      <p className={classes.newsletterText}>
        Receive curated insights,
        market trends, and exclusive updates from the E3D platform. Stay
        informed about blockchain innovations and never miss a beat.
      </p>
      <div>
        <img src="e3d-newsletter.png" alt="Newsletter" className={classes.newsletterImage} />
      </div>
      <button
        className={classes.newsletterButton}
        onClick={() => subscribeNewsletter()}
      >
        Subscribe Now
      </button>
    </section>
  );
};


const HomePage = ({ setShowHomePage, setShowShortcutPage, setShowSignup, showLogin, setShowLogin, handleLogin, isAuthenticated, showLogoutConfirm, setShowLogoutConfirm, handleLogout }) => {
  const classes = useStyles();

  const subscribeNewsletter = () => {
    setShowSignup(true);
  };

  return (
    <div className={classes.root}>
      {showLogin && (
        <LoginDialog
            onLogin={handleLogin}
            onClose={() => setShowLogin(false)}
            setShowSignup={setShowSignup}
          />
      )}
      {showLogoutConfirm && (
        <LogoutConfirmDialog
            onLogout={handleLogout}
            onClose={() => setShowLogoutConfirm(false)}
          />
      )}

      {/* Header */}
      <div className={classes.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="fut_logo_200.png" alt="FutCo Logo" className={classes.logo} />
          <span className={classes.title}>E3D Beta</span>
        </div>
        <button className={classes.loginButton} onClick={() => {isAuthenticated ? setShowLogoutConfirm(true) : setShowLogin(true)}}>
          {isAuthenticated ? "Logout" : "Login"}
        </button>
      </div>

      {/* Hero Section */}
      <HeroSection setShowHomePage={setShowHomePage} setShowShortcutPage={setShowShortcutPage}/>

      {/* About Section */}
      <AboutSection />

      {/* Features Section */}
      <FeaturesSection />

      {/* Network Graph Section */}
      <NetworkGraphSection />

      {/* E3D Token Section */}
      <TokenSection />

      {/* Features Section */}
      <PodcastSection />

      {/* Newsletter Section */}
      <NewsletterSection subscribeNewsletter={subscribeNewsletter}/>

      {/* Footer */}
      <footer className={classes.footer}>
        <p className={classes.footerText}>© 2025 FutCo LLC. All rights reserved.</p>
        <a
          href="https://discord.gg/r2cvGF55"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.discordIcon}
        >
          <i className="fab fa-discord"></i>
        </a>
      </footer>
    </div>
  );
};

export default HomePage;
