import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  ShortcutpageContainer: {
    fontFamily: "'Inter', sans-serif",
  },
  headerText: {
    fontSize: '2.5rem',
    color: '#fff', // White text
    marginBottom: '30px',
    fontWeight: "bold",
  },
  buttonsContainer: {
    display: 'flex',
    gap: '15px', // Adjust gap between buttons
    justifyContent: 'center',
    textAlign: 'center',
    
    // Media query for small devices in portrait mode
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.00rem", // Half the size on mobile
    },

    '@media (orientation: portrait)': {
      flexDirection: 'column',
      fontSize: "1.00rem", // Half the size on mobile
    },
  },
  ShortcutpageButton: {
    padding: '10px 20px',
    fontSize: '1.1rem !important', // Correct way to set the font size
    width: '200px',
    height: '75px', // Default height
    borderRadius: '15px',
    border: 'none',
    marginBottom: "0px",
    marginTop: "0px",
    fontWeight: "700 !important", // Ensure font-weight is applied
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Add shadow for depth
    cursor: 'pointer',
    transition: 'all 0.3s ease', // Smooth transitions for hover effects
    textTransform: 'none !important', // Ensure original case is retained
    '&:hover': {
      transform: 'translateY(-3px)', // Lift the button slightly
      boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.15)', // Increase shadow on hover
      background: 'linear-gradient(135deg, #f7f7f7, #eaeaea)', // Slightly darker gradient on hover
    },

    // Media query for portrait orientation and small devices
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: '65px', // Default height
    },

    '@media (orientation: portrait)': {
      width: '300px',
      height: '45px', // Default height
    },

    '@media (orientation: landscape)': {
      width: '155px',
      height: '75px', // Default height
    },
  },
  buttonTokenGraph: {
    backgroundColor: '#f3e5f5', // Subtle purple
  },
  buttonNFTViewer: {
    backgroundColor: '#fff3e0', // Subtle orange
  },
  buttonOpenPCAP: {
    backgroundColor: '#d2b48c', // Light brown
  },
  buttonTokenPrice: {
    backgroundColor: '#f3e3f2fde5f5', // Subtle purple
  },
  buttonPodcast: {
    backgroundColor: '#e3f2fd', // Subtle blue
  },
  buttonNewsletter: {
    backgroundColor: '#e0f7fa', // Subtle cyan
  },
  buttonUserGraph: {
    backgroundColor: '#e8f5e9', // Subtle green
  },
}));

const ShortcutPage = ({ setShowShortcutPage, setShowHomePage }) => {
  const classes = useStyles();

  const goToTokenGraph = () => {
    console.log('Go to 3D Token Graph');
    window.location.href = './token';
  };

  const goToUserGraph = () => {
    window.location.href = './token?organic&users';
  };

  const goToNFTViewer = () => {
    window.location.href = './nft';
  };

  const goToNewsletter = () => {
    window.location.href = './news';
  };

  const goToTokenPrice = () => {
    window.location.href = './price';
  };

  const goToPcap = () => {
    //openPcapRef.current.click();
    window.location.href = './pcap';
  };

  const handleButtonClick = (navigationFunction) => {
    setShowShortcutPage(false);
    setShowHomePage(false);
    navigationFunction();
  };

  const handleBackgroundClick = () => {
    setShowShortcutPage(false);
  };

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return ( 
    <div className={classes.ShortcutpageContainer} onClick={handleBackgroundClick}>
      <div className={classes.buttonsContainer} onClick={stopPropagation}>
        <button
          className={`${classes.ShortcutpageButton} ${classes.buttonTokenGraph}`}
          onClick={() => handleButtonClick(goToTokenGraph)}
        >
          3D Token Graph  
        </button>
        <button
          className={`${classes.ShortcutpageButton} ${classes.buttonUserGraph}`}
          onClick={() => handleButtonClick(goToUserGraph)}
        >
          3D User Graph
        </button>
        <button
          className={`${classes.ShortcutpageButton} ${classes.buttonNFTViewer}`}
          onClick={() => handleButtonClick(goToNFTViewer)}
        >
          NFT Explorer
        </button>
        <button
          className={`${classes.ShortcutpageButton} ${classes.buttonTokenGraph}`}
          onClick={() => handleButtonClick(goToTokenPrice)}
        >
          Token Price List  
        </button>
        <button
          className={`${classes.ShortcutpageButton} ${classes.buttonNewsletter}`}
          onClick={() => handleButtonClick(goToNewsletter)}
        >
          E3D AI Newsletter
        </button> 
        {!isMobile && (
          <button
            className={`${classes.ShortcutpageButton} ${classes.buttonOpenPCAP}`}
            onClick={() => handleButtonClick(goToPcap)}
          >
            Open PCAP File
          </button>
        )}
      </div>
    </div>
  );
};

export default ShortcutPage;
