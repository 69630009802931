import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    '@global': {
      body: {
        margin: 0,
        padding: 0,
        overflowX: 'hidden', // Prevents horizontal scroll
        backgroundColor: 'black',
      },
    },
    app: {
      maxWidth: '100%',
      overflowX: 'hidden',
    },
    iconColumn: {
      width: '24px',
      height: '24px',
      paddingLeft: '0px',
      paddingRight: '0px',
      marginRight: '0px',
      paddingTop: '0px',
      paddingBottom: '0px',
      display: 'flex', // Turns on flexbox layout mode
      alignItems: 'center', // Centers children vertically in the flex container
      justifyContent: 'center', // Centers children horizontally in the flex container
      textAlign: 'center', // Centers text inside the block-level element or container
      objectFit: 'contain', /* This ensures that the image's aspect ratio is maintained */
      cursor: 'pointer', /* Changes the cursor on hover to indicate it's clickable */
    },
    nodeContainer: {
      padding: '0px', // This sets the padding on all sides to 0
      margin: '0px', // This sets the margin on all sides to 0
      display: 'flex', 
      overflowX: 'scroll', 
      width: 64, 
      height: 64, 
      position: 'relative', 
      whiteSpace: 'nowrap', 
      flexShrink: 0
    },
    squareButton: {
      padding: '0px 0px', // Add padding (adjust as needed)
      margin: '0px 0px', // Add margin (adjust as needed)
      backgroundColor: 'white',
      color: 'black',
      marginRight: '0px', // No space between buttons
      borderRadius: '0px', // Makes corners squared
      fontSize: '16px', // Smaller font size
      textTransform: 'none', // Prevents text from being transformed to uppercase
      textDecoration: 'none', // Style it like a link
    },
    scrollableContent: {
      overflowY: 'auto', /* Enable vertical scrolling */
      paddingRight: '15px', /* Add padding to the right to create space for the scrollbar */
      boxSizing: 'content-box', /* Ensure padding is not included in the element's width */
    },
    tableRow: {
      padding: '0',
      margin: '0',
      transition: 'background-color 0.2s ease-in-out', // Smooth transition for background color
      '&:not(:last-child)': {
        borderBottom: '0px solid transparent', // No border by default
      },
      '&:hover': {
        backgroundColor: '#333', // Change to dark gray background on hover
      },
    },    
    table: {
      width: '100%',
      tableLayout: 'auto',
      borderCollapse: 'collapse',
      fontFamily: "'Roboto Mono', monospace", // Ensure the font family is in quotes
      fontSize: '14px',
    },
    tableCell: {
      textAlign: 'left',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: '0px',
      margin: '0',
      whiteSpace: 'nowrap',
      border: 'none', // Remove borders or set them to transparent
    },
    tableCellNumber: {
      padding: '0px',
      margin: '0',
      whiteSpace: 'nowrap',
      border: 'none', // Remove borders or set them to transparent
      textAlign: 'right', // Aligns text to the right
    },
    tableHeader: {
      alignItems: 'center', // Center items vertically
      justifyContent: 'flex-end', // Align the header label and the sort icon to the end (right)
      width: 'auto', // Ensure that the width is only as wide as needed
      position: 'sticky', // Make header sticky
      height: '20px',
      top: 0, // Stick to the top of the table
      zIndex: 1, // Ensure it's above other elements
      color: 'white',
      backgroundColor: 'black',
      border: 'none',
      padding: '0px 0px', // Further reduce padding
      margin: '0',
      textDecoration: 'none',
      cursor: 'pointer',
      textTransform: 'none',
    },
    tableHeaderRight: {
      display: 'flex',
      justifyContent: 'flex-end', // Align content to the right
      alignItems: 'center',
      textAlign: 'right',
      width: '100%', // Use full width to allow flex alignment
      position: 'relative', // Remove 'sticky' if not needed here
      height: 'auto',
      color: 'white',
      backgroundColor: 'black',
      padding: '0 10px', // Adjust padding for spacing
      margin: 0,
      cursor: 'pointer',
      textTransform: 'none',
    },    
    tableBody: {
      margin: '0',
    },
    resizer: {
      display: 'inline-block',
      width: '2px',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 1,
      touchAction: 'none',
      backgroundColor: 'lightgray', // Optional for visibility
      cursor: 'col-resize',
    },
    linkButtonStyle: {
      color: 'white',
      backgroundColor: 'transparent',
      border: 'none',
      padding: '2px 4px', // Further reduce padding
      margin: '0',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '12px',
      textTransform: 'none',
      lineHeight: '1', // Reduce line height
    },
    listTextStyle: {
      color: 'white',
      backgroundColor: 'transparent',
      border: 'none',
      padding: '2px 4px', // Further reduce padding
      margin: '0',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '14px',
      textTransform: 'none',
      lineHeight: '1', // Reduce line height
    },
    goldButtonStyle: {
      color: '#D4AF37', // This represents a shade of antique gold
      backgroundColor: 'transparent',
      border: 'none',
      padding: '2px 4px', // Further reduce padding
      margin: '0',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '12px',
      textTransform: 'none',
      lineHeight: '1', // Reduce line height
    },
    listButtonStyle: {
      color: 'white',
      backgroundColor: 'transparent',
    },
    iconButtonStyle: {
        color: 'white',
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0px 0px', // Further reduce padding
        margin: '0',
        textDecoration: 'none',
        cursor: 'pointer',
        textTransform: 'none',
        lineHeight: '1', // Reduce line height
        width: '12px',
        height: '12px',
    },
    menuButtonStyle: {
      color: 'white',
      backgroundColor: 'transparent',
      border: 'none',
      padding: '2px 4px', // Further reduce padding
      margin: '0',
      textDecoration: 'none',
      cursor: 'pointer',
      fontSize: '16px',
      textTransform: 'none',
      lineHeight: '1', // Reduce line height
    },
    menuStyle: {
      marginTop: '20px',
    },
    wideCell: {
      width: '50%', // Adjust width as needed
    },
    topContainer: {
      display: 'flex',
      flex: 1,
      width: '100%',
      backgroundColor: 'black', // or any color you prefer
      padding: '2px', // Minimized padding
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '24px', // Set a minimum height if necessary
      borderBottom: '1px solid blue', // Thin blue line at the bottom
    },  
    bottomContainer: {
      display: 'flex',
      width: '100%',
      backgroundColor: 'black',
      padding: '2px',
      justifyContent: 'flex-start', 
      alignItems: 'center',
      minHeight: '32px',
      borderTop: '1px solid blue',
      position: 'absolute',
      bottom: 0,
      fontFamily: '"Arial", sans-serif',
    },
    leftAlignedContainer: {
      display: 'flex',
      justifyContent: 'flex-start', // Align items to the start of the main axis
      alignItems: 'center', // Vertical alignment in the center
    },
    rightAlignedContainer: {
      marginLeft: 'auto', /* Pushes the container to the right */
      display: 'flex',
      alignItems: 'center', /* Adjust alignment as needed */
    },
    leftPanel: {
      width: '40px', 
      height: '100%', 
      backgroundColor: 'black',
      borderRight: '1px solid blue',
    },
    panelContainer: {
      width: '400px', // Fixed width
      //height: 'calc(100vh - 345px)', // 100vh is the full height of the viewport, adjust 200px to the height of your other fixed elements
      backgroundColor: 'black', // or any color you prefer
      overflowY: 'auto', // This will add a vertical scrollbar to the container
      padding: '0px',
      borderLeft: '1px solid blue', // Thin blue line for the left border
      paddingRight: '15px', /* Add padding to the right to create space for the scrollbar */
      boxSizing: 'content-box', /* Ensure padding is not included in the element's width */
    },
    linkContainer: {
      width: '100%',
      height: 'calc(100vh - 200px)', // 100vh is the full height of the viewport, adjust 200px to the height of your other fixed elements
      backgroundColor: 'black',
      overflowY: 'auto', // This will add a vertical scrollbar to the container
      padding: '2px',
      display: 'flex',
      justifyContent: 'flex-start', 
      alignItems: 'left',
      maxHeight: '300px',
      borderTop: '1px solid blue',
      position: 'absolute',
      bottom: 0,
      paddingRight: '15px', /* Add padding to the right to create space for the scrollbar */
      boxSizing: 'content-box', /* Ensure padding is not included in the element's width */
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      display: 'flex',
      //height: 'calc(100vh - 48px)', // Adjust height considering the height of topContainer
    },
    graphContainer: {
      display: 'flex',
      flexDirection: 'row', // Stack children vertically
      justifyContent: 'center', // Centers content horizontally
      alignItems: 'center', // Centers content vertically
      flex: 1,
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      //height: 'calc(100% - 29px)',
      backgroundColor: 'black',
    },
    tokenContainer: {
      display: 'flex',
      flexDirection: 'column', // Stack children vertically
      //justifyContent: 'center', // Centers content horizontally
      //alignItems: 'center', // Centers content vertically
      flex: 1,
      flexGrow: 1,
      flexShrink: 1,
      overflow: 'hidden',
      //height: 'calc(100% - 29px)',
      backgroundColor: 'black',
    },
    tokenInfoComponent: {
      position: 'absolute', // Position it absolutely to float above the ForceGraphComponent
      top: theme.spacing(2), // Adjust top as needed
      right: theme.spacing(2), // Adjust right as needed
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      boxShadow: '0 2px 5px rgba(0,0,0,0.2)', // Optional shadow for better readability
      maxWidth: '300px', // Maximum width of the TokenInfoComponent, adjust as needed
      overflowY: 'auto', // Enable scrolling for overflow content
      maxHeight: 'calc(100% - 48px)', // Adjust the height so it doesn't overlap with the bottom of the container
    },
    nodeListContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '100%', 
      overflowY: 'auto', 
      width: '100%',
      flexShrink: 0,
      minWidth: '150px',
      maxWidth: '100%',
      overflow: 'auto',
      backgroundColor: 'black',
      '& p': {
        margin: '0px 0',
        padding: '0px 0px',
        fontSize: '0.8rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        }
      }
    },
    nodeListContainerMobile: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '300px', 
      overflowY: 'auto', 
      width: '100%',
      flexShrink: 0,
      minWidth: '150px',
      maxWidth: '427px',
      width: '100%',
      overflow: 'auto',
      backgroundColor: 'black',
      '& p': {
        margin: '0px 0',
        padding: '0px 0px',
        fontSize: '0.8rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        }
      }
    },
    linkListContainer: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '300px', 
      overflowY: 'auto', 
      width: '100%',
      flexShrink: 0,
      minWidth: '150px',
      maxWidth: '100%',
      overflow: 'auto',
      backgroundColor: 'black',
      '& p': {
        margin: '0px 0',
        padding: '0px 0px',
        fontSize: '0.8rem',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        fontWeight: 400,
      },
      '& a': {
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        }
      }
    },
    footerValueStyle: {
      color: 'black',
      backgroundColor: 'lightgray',
      minWidth: '6ch',
      display: 'inline-block',
    },
    whiteText: {
      color: 'white',
    },
    button: {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      padding: '4px 8px',
      backgroundColor: 'lightgray',
      color: 'black',
      marginRight: '8px',
      '&:hover': {
        backgroundColor: 'darkgray',
      },
    },
    graphWrapper: {
      zIndex: 1, // Lower zIndex than the spinner
      position: 'relative',
    },
    spinnerWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      color: 'yellow',
      backgroundColor: 'transparent',
      zIndex: 9999, // Ensures the spinner is above all other elements
    },
    searchField: {
      backgroundColor: 'black',
      paddingRight: '10px',
      color: 'white', // This changes the label color, but not the input text color
      width: '500px', // Set width as desired
      '& input': { // This targets the input element inside the TextField
          color: 'white', // This will change the input text color to white
          padding: '8px 14px', // Decrease padding to reduce height
          fontSize: '0.875rem', // Adjust font size if necessary
      },
      '& .MuiOutlinedInput-root': { // This targets the root of the OutlinedInput
          '& fieldset': { // This targets the fieldset
              borderColor: 'white', // This changes the border color
          },
          '&:hover fieldset': {
              borderColor: 'white', // This changes the border color on hover
          },
          '&.Mui-focused fieldset': { // This targets the fieldset when the input is focused
              borderColor: 'white', // This changes the border color when focused
          },
      },
    },
    spacepacketText: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: '4rem', // Adjust the size as needed
      color: 'white',
      zIndex: 2, // Ensure it's above other content
    },
    progressContainer: {
      width: '300px',
      backgroundColor: '#ddd',
      display: 'none', // Hidden initially
      transition: 'opacity 0.3s ease',
      opacity: 0, // Start with 0 opacity
      marginLeft: '20px', // Adjust this value as needed for spacing
    },
    progressContainerVisible: { // Updated class name for consistency
      display: 'block',
      opacity: 1, // Fully visible when active
    },
    progressBar: {
      width: '0%', // Ensure this is a string
      height: '20px',
      backgroundColor: '#4CAF50',
      transition: 'width 0.3s ease',
    },
    modalBackdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '1000',
    },
    modalContent: {
      background: 'white',
      padding: '20px',
      borderRadius: '5px',
      width: '75%',
      maxHeight: '75%',
      overflowY: 'auto',
    },
    signupDialog: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1001,
    },
    verifyEmailDialog: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '5px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      zIndex: 1001,
    },
    codeInputContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    codeInput: {
      width: '1em', /* Only wide enough for one character */
      margin: '0 5px', /* Add some spacing between the fields */
      textAlign: 'center',
      border: '2px solid #000', /* Fat and bold borders */
      fontSize: '1.5em', /* Larger font size */
      appearance: 'textfield', /* Remove default appearance */
      MozAppearance: 'textfield', /* Firefox */
      WebkitAppearance: 'none', /* Safari and Chrome */
      outline: 'none', /* Remove the outline on focus */
    },
    overlay: {
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300, // Make sure this is above everything else
        margin: 0,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
    },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      right: 0,
      inset: 0,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      display: 'flex',
      alignItems: 'flex-start',
      zIndex: 1300, // Make sure this is above everything else
      margin: 0,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    dialog: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& h2': {
          margin: theme.spacing(2, 0),
        },
    },
    button: {
        margin: theme.spacing(1),
        padding: theme.spacing(1, 4),
        borderRadius: theme.shape.borderRadius,
        textTransform: 'none',
    },
    cancelButton: {
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
    },
    logoutButton: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.getContrastText(theme.palette.error.main),
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
    },
    loginDialog: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiTextField-root': {
          margin: theme.spacing(1),
          width: '25ch',
        },
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    disabledIcon: {
        opacity: 0.3, // Or any other style you prefer for disabled icons
    },
    scrollDialog: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '1200px', // Set the max width of the dialog
      width: '100%', // Use a percentage width to make it responsive
      maxHeight: '80vh', // Make sure the dialog doesn't exceed the viewport height
      overflowY: 'auto', // Add scroll to the Y axis if the content overflows
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      '& h2': {
        margin: theme.spacing(2, 0),
      },
    },
    priceDetailsDialog: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '600px', // Set the max width of the dialog
      width: '100%', // Use a percentage width to make it responsive
      maxHeight: '80vh', // Make sure the dialog doesn't exceed the viewport height
      overflowY: 'auto', // Add scroll to the Y axis if the content overflows
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      '& h2': {
        margin: theme.spacing(2, 0),
      },
    },
    activeRow: {
      // Your styles for highlighting the row, e.g., background color
      backgroundColor: '#e8e8e8',
    },
    toolIconStyle: {
      margin: theme.spacing(0), // No external margins
      padding: '4px', // More padding to make it feel like a button
      color: 'rgba(255, 255, 255, 1.0)', // Super bright white by default
      borderRadius: '8px', // Ensure it always has rounded edges
      transition: 'background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out',
  
      '&:hover, &:focus': { // Ensure the hover effect applies correctly
        backgroundColor: '#666 !important', // Dark gray background on hover
        borderRadius: '8px', // Maintain rounded edges on hover
      },
  
      '&.MuiIconButton-root': {
        borderRadius: '8px', // Override default Material-UI styles
      },
  
      '&.selected': { // Apply this class when selected
        backgroundColor: '#666 !important', // Dark gray background
        borderRadius: '8px',
      },
  
      '& svg': { // Icon styling
        fontSize: '1em',
      },
    },
    toolIconStyle2: {
      margin: theme.spacing(0), // No external margins
      padding: '5px', // Reduce padding inside the IconButton
      paddingLeft: '10px',
      color: 'rgba(255, 255, 255, 1.0)', // Super bright white by default
      transition: 'background-color 0.2s ease-in-out, border-radius 0.2s ease-in-out',
      borderRadius: '4px', // Ensures a slight rounding even when not hovered
      '&:hover': {
        backgroundColor: '#222', // Dark gray background on hover
        borderRadius: '8px', // Rounded edges on hover
      },
      '& svg': { // Styling for SVG icons (e.g., FontAwesome or Material-UI icons)
        fontSize: '0.9em', // Adjust icon size
      },
    },
    centerAlignedContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'black',
      color: 'white',
    },
    timeContainer: {
      display: 'flex',
      marginLeft: '10px',
      marginRight: '10px',
    },
    durationContainer: {
      display: 'flex',
      marginLeft: '10px',
      marginRight: '10px',
    },
    durationDisplay: {
      display: 'inline-block', // Ensures proper box alignment
      width: '60px', // Set a constant width
      padding: theme.spacing(0.3),
      borderRadius: '4px',
      border: '1px solid #666',
      backgroundColor: '#222',
      color: '#fff',
      fontSize: '12px', // Consistent font size
      textAlign: 'center', // Align text within the box
    },
    rangeDisplay: {
      display: 'inline-block', // Ensures proper box alignment
      width: '60px', // Set a constant width
      padding: theme.spacing(0.3),
      borderRadius: '4px',
      border: '1px solid #666',
      backgroundColor: '#222',
      color: '#fff',
      fontSize: '12px', // Consistent font size
      textAlign: 'center', // Align text within the box
    },
    maxTransactionsContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      marginRight: '10px',
    },
    autoRefreshContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      marginRight: '10px',
    },
    tourContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      marginRight: '10px',
    },
    chainContainer: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      marginRight: '10px',
      border: `1px solid ${theme.palette.grey[500]}`,
    },
    maxTransactionsLabel: {
      color: 'white',
    },
    maxTransactionsInput: {
      width: '50px',
      marginLeft: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.grey[500]}`,
    },
    toggleButton: {
      borderRadius: theme.shape.borderRadius,
      border: 'none',
      cursor: 'pointer',
      '&:hover': {
        opacity: 0.8,
      },
      backgroundColor: theme.palette.success.main,
    },
    buttonActive: {
      backgroundColor: theme.palette.error.main,
    },
    progressContainer: {
      backgroundColor: theme.palette.grey[400],
      height: '5px',
      width: '100%',
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      display: 'none',
    },
    progressContainerVisible: {
      display: 'block',
    },
    label: {
      color: 'white', // Ensures labels are white
      fontSize: '1rem', // Adjust the font size as needed
    },
    toolbarContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: 'black', // Solid black background
      color: '#fff',
      width: '100%',
      height: '48px', // Fixed height for the toolbar
      alignItems: 'center', // Ensure content is vertically aligned
      borderTop: 'none', // Removed top border
      padding: 0, // No padding for a compact layout
    },
    advancedToolbar: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
    toolbarSection: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    toolbarLabel: {
      fontSize: '14px', // Consistent font size
      fontWeight: 'bold', // Make all labels bold
      color: '#fff',
    },
    rangeDisplay: {
      fontSize: '10px', // Smaller font for Range values
      color: '#fff',
    },
    iconButton: {
      color: '#fff',
      margin: 0, // Remove any default margin
      padding: theme.spacing(0.2), // Further reduce padding for compactness
      fontSize: '16px', // Adjust icon size
      minWidth: '24px', // Ensure compact button width
      height: '24px', // Ensure compact button height
      '&:hover': {
        color: '#4caf50',
      },
    },    
    toolbarContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      backgroundColor: 'black',
      color: '#fff',
      height: '24px',
      alignItems: 'center',
      padding: 0,
    },
    toolbarSection: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    toolbarLabel: {
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#ccc',
    },
    compactContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative', // Needed for positioning the date picker
    },
    dateDisplay: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      position: 'relative', // Make it the parent for the absolute picker
      width: '160px',
      padding: theme.spacing(0.3),
      borderRadius: '4px',
      border: '1px solid #666',
      backgroundColor: '#222',
      color: '#fff',
      fontSize: '12px',
    },
    calendarIcon: {
      color: '#fff',
      cursor: 'pointer',
      '&:hover': {
        color: '#4caf50',
      },
    },
    datePickerOverlay: {
      position: 'absolute',
      top: '0',
      left: '20px',
      zIndex: 10, // Ensure it's on top
      width: '100%',
      backgroundColor: '#333', // Match the toolbar's style
      borderRadius: '4px',
      padding: 0,
      margin: 0,
    },
    dateTimePicker: {
      width: '100%', // Ensure it fits within the overlay
      '& input': {
        width: '100%', // Ensure input field matches container size
        backgroundColor: 'black',
        color: 'white',
        border: '1px solid #666',
        borderRadius: '4px',
        padding: theme.spacing(0.5),
        fontSize: '12px',
        textAlign: 'center',
      },
    },
    inputField: {
      width: '60px', // Reduced width
      padding: theme.spacing(0.3), // Reduced padding
      borderRadius: '4px',
      border: '1px solid #666',
      backgroundColor: '#222',
      color: '#fff',
      fontSize: '12px', // Consistent font size for values
      '&:focus': {
        borderColor: '#4caf50',
        outline: 'none',
      },
    },
    statisticsContainer: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1), // Reduced spacing
    },
    statisticsLabel: {
      fontSize: '12px', // Consistent font size
      fontWeight: 'bold', // Make labels bold
      color: '#fff',
    },
    statisticsValue: {
      display: 'inline-block', // Ensures proper box alignment
      width: '40px', // Set a constant width
      padding: theme.spacing(0.3),
      borderRadius: '4px',
      border: '1px solid #666',
      backgroundColor: '#222',
      color: '#fff',
      fontSize: '12px', // Consistent font size
      textAlign: 'center', // Align text within the box
    },
    progressBarContainer: {
      position: 'relative',
      width: '100%',
      height: '4px',
      backgroundColor: '#444',
      marginTop: theme.spacing(0.5), // Reduced margin
    },
    progressBar: {
      height: '100%',
      backgroundColor: '#4caf50',
      transition: 'width 0.3s ease',
    },
    menuStyle: {
      backgroundColor: '#333',
      color: '#fff',
      '& .MuiMenuItem-root': {
        '&:hover': {
          backgroundColor: '#4caf50',
        },
      },
    },
  }));
  

  export default useStyles;