// config.js
//export const SERVER_NAME = 'https://e3d.ai/apitest';
export const SERVER_NAME = 'https://e3d.ai/api';
//export const SERVER_NAME = 'http://localhost:3002';
export const PCAP = 0;
export const ETHERSCAN = 1;
export const BSCSCAN = 2;
export const genericTokenIcon = `${process.env.PUBLIC_URL}/eth_icon.png`;
export const genericUserIcon = `${process.env.PUBLIC_URL}/smile_icon.png`;
export const futLogoIcon200 = `${process.env.PUBLIC_URL}/fut_logo_200.png`;
export const metamaskLogo = `${process.env.PUBLIC_URL}/metamask.svg`;
export const etherscanLogo = `${process.env.PUBLIC_URL}/etherscan-logo-circle-light.png`;
export const genericNodeIcon = `${process.env.PUBLIC_URL}/blue_dot_icon_22.png`;
