import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import TokenInfoComponent from './TokenInfoComponent';
import { IconButton, Tooltip } from '@material-ui/core';
import useStyles from './sharedStyles'; 
import { FaHeart } from 'react-icons/fa';
import { SERVER_NAME } from './config';
import { futLogoIcon200 } from './config';
import axios from 'axios';

 
const MediaRendererSmall = ({ src, index, currentNodeIndex, setCurrentNodeIndex, futLogoIcon200, setImageFailed }) => {

    const videoRef = useRef(null);

    useEffect(() => {
      const handleVideoClick = () => {
        setCurrentNodeIndex(index);
      };
  
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.addEventListener('click', handleVideoClick);
      }
  
      return () => {
        if (videoElement) {
          videoElement.removeEventListener('click', handleVideoClick);
        }
      };
    }, [index, setCurrentNodeIndex]);
  
    // Extract the file extension
    const extension = src.split('.').pop().toLowerCase();
  
    // Check if the file is a video or image
    if (extension === 'mp4') {
      return (
        <video 
            ref={videoRef}
            autoPlay loop muted playsInline
            style={{ 
                cursor: 'pointer',
                border: index === currentNodeIndex ? '3px solid green' : 'none', 
            }} 
        >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <img 
            src={src} 
            style={{ 
                width: '100%', 
                height: '100%', 
                border: index === currentNodeIndex ? '3px solid green' : 'none',
                boxSizing: 'border-box',
                flexShrink: 0 
            }} 
            alt={futLogoIcon200} 
            onClick={() => {
                setCurrentNodeIndex(index);
            }}
            loading="lazy"
            onError={() => setImageFailed(true)}
        />
      );
    
    } 
};

const MediaRendererBig = ({ src, futLogoIcon200, setIsTourRunning, useAspectRatio, dimensions }) => {
    const videoRef = useRef(null);
    const extension = src.split('.').pop().toLowerCase();
  
    useEffect(() => {
        const videoElement = videoRef.current;
    
        if (videoElement) {
            const handleCanPlay = () => {
                //videoElement.play();
            };
    
            videoElement.addEventListener('canplay', handleCanPlay);
    
            return () => {
                videoElement.removeEventListener('canplay', handleCanPlay);
            };
        }
    }, [src]);
    
    // Check if the file is a video or image
    if (extension === 'mp4') {
      return (
        <video
            ref={videoRef}
            key={src} // Forces re-render 
            style={{
                width: useAspectRatio ? dimensions.width : '100%',
                height: useAspectRatio ? dimensions.height - 64 : '100%',
                cursor: 'pointer',
                objectFit: useAspectRatio ? 'fill' : 'contain'
            }}
            autoPlay loop controls playsInline muted 
        >
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
      );
    } else {
      return (
        <img src={src} 
            style={{
            width: useAspectRatio ? dimensions.width : '100%',
            height: useAspectRatio ? dimensions.height - 64 : '100%',
            objectFit: useAspectRatio ? 'fill' : 'contain'
            }}
            alt={futLogoIcon200} 
            onClick={() => setIsTourRunning(false)}
        />
      );
    
    }
};


function Node({ node, index, currentNodeIndex, setCurrentNodeIndex, setImageKey, containerRefs }) {
    const classes = useStyles();
    const { ref, inView } = useInView({ triggerOnce: true });
    const [imageFailed, setImageFailed] = React.useState(false);
 
    // Check if node and classes are defined
    if (!node || !classes) {
      return null;
    }

    return (
      <div ref={ref}>
        <Tooltip title={node.label}>
            <div ref={containerRefs[index]} className={classes.nodeContainer} >  
              {inView && !imageFailed && (
                  <MediaRendererSmall src={node.icon} index={index} currentNodeIndex={currentNodeIndex} setCurrentNodeIndex={setCurrentNodeIndex} futLogoIcon200={futLogoIcon200} setImageFailed={setImageFailed} />
              )}
            </div>
        </Tooltip>
      </div>
    );
}

export const TokenContainer = (props) => {
  const { imageKey, tokenContainerRef, dimensions, getCurrentNodeIcon, getCurrentNode, futLogoIcon200, graphData, currentNodeIndex, setCurrentNodeIndex, setImageKey, goToPreviousNode, goToNextNodeManually, filterNFT, filterToken, showChart, tokenData, handleCloseTokenInfo, isMobile, isPortrait, setIsTourRunning, useAspectRatio, userID, setShowLogin, vote } = props;
  const classes = useStyles();
  const [currentNode, setCurrentNode] = useState(null);

  // Create a ref for each node
  const containerRefs = graphData.nodes.map(() => React.createRef());

  function isElementInViewport(el) {
    if (!el) {
      return false;
    }
    
    const rect = el.getBoundingClientRect();
    const container = document.getElementById('tokenContainer');
  
    if (!container) {
      return false;
    }
  
    const containerRect = container.getBoundingClientRect();
  
    return (
      rect.top >= containerRect.top &&
      rect.bottom <= containerRect.bottom &&
      rect.left >= containerRect.left &&
      rect.right <= containerRect.right
    );
  }

    // Add effect to scroll the current node into view when it changes
    useEffect(() => {
        if (currentNodeIndex >= 0 && currentNodeIndex < containerRefs.length && containerRefs[currentNodeIndex].current) {
            containerRefs[currentNodeIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [currentNodeIndex]);

    // Add keyboard event listener
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'ArrowLeft':
                    if (currentNodeIndex > 0) {
                        setCurrentNodeIndex(currentNodeIndex - 1);
                    }
                    break;
                case 'ArrowRight':
                    if (currentNodeIndex < containerRefs.length - 1) {
                        setCurrentNodeIndex(currentNodeIndex + 1);
                    }
                    break;
                default: 
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentNodeIndex, containerRefs]);

    // Get the number of likes for the current node
    async function getAllVotes() {
        const node = getCurrentNode();
        if (!node) {
            return 0;
        }
    
        console.log('getAllVotes()');

        const address = node.id;
        let response;
        try {
            response = await axios.get(`${SERVER_NAME}/getVotes`, {
                params: {
                    tokenAddress: address,
                },
            });
        } catch (error) {
            console.error('getVotes error:', error);
            const message = (error.response && error.response.data) ? error.response.data.message : error;
            console.error(message);
            alert(message);
            return;
        }
        const data = (response.data.length > 0) ? response.data[0] : [ { likes: 0, dislikes: 0 } ];
        //console.log(data);
        return data.likes;
    }

    // Get the user's vote for the current node
    async function getUserVote() {
      const node = getCurrentNode();
      if (!node) {
          return 0;
      }
  
      console.log('getUserVote()');

      const address = node.id;
      let response;
      try {
          response = await axios.get(`${SERVER_NAME}/getVote`, {
              params: {
                  tokenAddress: address,
                  userId: userID,
              },
          });
      } catch (error) {
          console.error('getVotes error:', error);
          const message = (error.response && error.response.data) ? error.response.data.message : error;
          console.error(message);
          alert(message);
          return;
      }
      const vote = response.data; // like, dislike, none
      return vote;
  }

  // Get the number of likes for the current node
  async function voteLocal(voteParam) {
    console.log('voteLocal() voteParam:', voteParam);
    await vote(voteParam);
    setUpdateVote(updateVote + 1);
    setCurrentNodeIndex(currentNodeIndex);
  }
   
    const [allVotes, setAllVotes] = useState({ likes: 0, dislikes: 0 });
    const [userVote, setUserVote] = useState('none'); // like, dislike, none
    const [updateVote, setUpdateVote] = useState(0); // trigger useEffect when vote changes

    useEffect(() => {
        const fetchAllVotes = async () => {
          const likes = await getAllVotes();
          setAllVotes({ likes: likes, dislikes: 0 });
        };
    
        const fetchUserVote = async () => {
          const vote = await getUserVote();
          setUserVote(vote);
        };

        fetchAllVotes();
        fetchUserVote();
    }, [graphData, currentNode, currentNodeIndex, updateVote]); // Add the variable that represents the current image to the dependency array
    
    useEffect(() => {
      // This effect will run whenever userVote or allVotes changes, triggering a re-render
    }, [userVote, allVotes]);
  
  return (
    <div id="tokenContainer" key={imageKey} ref={tokenContainerRef} tabIndex="-1" width={dimensions.width} height={dimensions.height} className={classes.tokenContainer}>
      
      {filterNFT && (
        <div className={classes.imageOverlay} style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '200px', height: '40px' }} >
            <div style={{ display: 'flex', alignItems: 'left', color: 'white' }}>
                <Tooltip title="I like this NFT">
                    <IconButton onClick={() => voteLocal(userVote)} className={classes.toolIconStyle}>
                        <FaHeart color={(userVote == 'like') ? "white" : "transparent"} stroke="white" strokeWidth="0.6em" />
                    </IconButton>
                </Tooltip>
                <span style={{ fontFamily: 'Arial, sans-serif', position: 'relative', top: '7px' }}>{allVotes.likes}</span>

                {/*
                <Tooltip title="I dislike this">
                    <IconButton onClick={() => vote('dislike')} className={classes.toolIconStyle}>
                        <FaThumbsDown color={(getDislikes() !== '0') ? "white" : "transparent"} stroke="white" strokeWidth="0.6em" />
                    </IconButton>
                </Tooltip>
                <span style={{ fontFamily: 'Arial, sans-serif' }}>{getDislikes()}</span>
                */}
            </div>
        </div>
      )}

      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100%', 
        height: dimensions.height - 64 }}
      >
        <MediaRendererBig src={getCurrentNodeIcon()} futLogoIcon200={futLogoIcon200} useAspectRatio={useAspectRatio} dimensions={dimensions} setIsTourRunning={setIsTourRunning}/>
 
      </div>
      {filterNFT && (
        <div style={{ display: 'flex', overflowX: 'auto', width: '100%', whiteSpace: 'nowrap' }}>
            {graphData.nodes.map((node, index) => 
                <Node 
                    key={index} 
                    node={node} 
                    index={index} 
                    currentNodeIndex={currentNodeIndex} 
                    setCurrentNodeIndex={setCurrentNodeIndex} 
                    setImageKey={setImageKey} 
                    containerRefs={containerRefs} 
                />
            )}
        </div>
      )}
      {(filterToken && showChart && tokenData) && (
        <TokenInfoComponent data={tokenData} onClose={handleCloseTokenInfo} isMobile={isMobile} isPortrait={isPortrait}/>
      )}
    </div>
  );
}